const data = [
  {
    title: 'Streamline your shop',
    icon: 'blog_side_arrow',
  },
  {
    title: 'Get paid faster',
    icon: 'blog_side_dollar',
  },
  {
    title: 'Understand your business',
    icon: 'blog_side_glass',
  },
];

export default data;
